<template>
  <TableTemplate
    :table-name="tableName"
    :table-columns="tableColumns"
    :use-language-selector="useLanguageSelector"
    :use-import="useImport"
  />
</template>

<script>
import TableTemplate from './TableTemplate';

export default {
  components: {
    TableTemplate
  },
  data() {
    return {
      tableName: 'machine',
      tableColumns: [
        {
          id: 'id',
          name: 'ID',
          allowEdit: false
        },
        {
          id: 'code_from',
          name: 'Code From'
        },
        {
          id: 'code_to',
          name: 'Code To'
        },
        {
          id: 'group_id',
          name: 'Group Name',
          lookup: {
            table: 'machine-group',
            displayExpr: 'name',
            valueExpr: 'id'
          }
        },
        {
          id: 'code',
          name: 'Code'
        },
        {
          id: 'name',
          name: 'Name'
        }
      ],
      useLanguageSelector: true,
      useImport: true
    };
  }
};
</script>
